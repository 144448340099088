<template>
  <div v-if="timeDialogVisible">
    <el-dialog :title="editTime ? '请输入做题时长（分钟）' : ''"
               :visible.sync="timeDialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500rem"
               center
               :before-close="handleClose">
      <el-input v-if="editTime" type="number" v-model="time" placeholder=""></el-input>
      <div v-else style="display: flex;justify-content: center;font-size: 24rem;font-weight: 500;">
        <span>已用时间：{{leftTime}} / {{totalTime}} 分钟</span>
      </div>
      <div v-if="editTime" class="btn_warp">
        <div @click="handleClose">取消</div>
        <div @click="setTimeSubmit">开始训练</div>
      </div>
      <div v-else class="btn_warp">
        <div @click="handleClose">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      time: 0,
      timeDialogVisible: false,
      editTime: false,
      leftTime: 0,
      totalTime: 0,
    }
  },
  methods: {
    setTimeSubmit () {
      this.$parent.setTimeSubmit(this.time)
      this.timeDialogVisible = false
      this.$message.success('设置成功')
    },
    handleClose () {
      this.timeDialogVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
.btn_warp {
  width: 100%;
  height: 70rem;
  border-top: 1rem solid #e5e5e5;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20rem;
    &:nth-child(1) {
      font-weight: bold;
      color: #999999;
      border-right: 1rem solid #e5e5e5;
    }
    &:nth-child(2) {
      font-weight: bold;
      color: #2196f3;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 5rem 20rem;
}

::v-deep .el-dialog__header {
  font-size: 18rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
::v-deep .el-dialog {
  border-radius: 20rem;
  height: 250rem;
  margin-top: 198rem !important;
}

::v-deep .el-form-item__label {
  color: #333333;
  font-size: 18rem;
  font-weight: 500;
}

.block {
  display: flex;
  margin-bottom: 20rem;
  span {
    padding: 7rem 18rem;
    font-size: 16rem;
    font-weight: bold;
    color: #666666;
    border-radius: 5rem;
    background: #f6f6f6;
    &:nth-child(2) {
      margin-left: 20rem;
    }
  }
}
.isActive {
  background: #007af0 !important;
  color: white !important;
}

::v-deep .el-dialog__close {
  display: none;
}

.desction {
  font-size: 16rem;
  font-weight: bold;
  color: #ff6147;
}
::v-deep .el-form-item__label {
  font-size: 20rem;
  font-weight: 500;
  color: #333333;
  line-height: 40rem;
}
::v-deep .el-input__inner {
  font-weight: 500;
  color: #999999;
  font-size: 16rem;
  height: 40rem;
  border-radius: 5rem;
  border: 1rem solid #cccccc;
}

::v-deep .el-textarea__inner {
  font-weight: 500;
  color: #999999;
  font-size: 16rem;
  height: 230rem;
  border-radius: 5rem;
  border: 1rem solid #cccccc;
}

::v-deep .el-dialog__title {
  color: #333333;
  font-weight: 500;
  font-size: 20rem;
  line-height: 33rem;
}
</style>