<template>
  <div class="topic_warp">
    <div class="big"
         v-if=" question.question_code_head == '' && Number(question.question_score) == 0">
      <div class="left">
        <div class="head content">
          <div>
            <!-- {{question.question_category_name}}: -->
            (<span v-if="question.question_type_name">{{question.question_type_name}},</span>{{question.question_big_score}}分,{{question.source_name}})
          </div>
          <div class="topic_id">
            <!-- ID：{{$rands(question.question_id)}} -->
            ID：{{question.question_id}}

          </div>
        </div>

        <div class="topic_content content">
          <div v-html="cleanedQuestionContent"></div>
        </div>
      </div>

      <div class="right">
        <div class="topic"
             v-for="(item,index) in question.question_children"
             :style="{'margin-top':index==0?'':'20rem'}"
             :key="index">
          <p class="title">
            <!-- {{item.question_category_name}}: -->
            <span class="yuan"><span :style="{'color':item.has_knowledge?'#f00':'#666'}">题目{{item.answer_id}}</span> <span v-if="item.subject_id ==11">原第{{ item.question_serial_head }}小题</span> ({{item.question_type_name}},{{item.question_score}}分，{{item.source_name}})</span>

            <span class="topic_id">
              <!-- ID：{{$rands(item.question_id)}} -->
              ID：{{item.question_id}}
            </span>
          </p>
          <div class="content">
            <div v-html="item.question_content"></div>
          </div>
          <div class="selects_warp">
            <Select ref="SelectRef"
                    :answer.sync="item.answer"
                    :questionId="item.question_id"
                    @setAnwer="setAnwer"
                    :type="item.topic + ''" />
          </div>
        </div>
      </div>
    </div>
    <div class="small"
         v-else>
      <p class="title">
        <span class="yuan">题目{{question.answer_id}} ({{question.question_type_name}}<span v-if="question.question_category_name">，{{question.question_category_name }}</span>:{{question.question_score}}分，{{question.source_name}})</span>

        <span class="topic_id">
          <!-- ID：{{$rands(question.question_id)}} -->
          ID：{{question.question_id}}
        </span>
      </p>

      <div class="content">
        <div v-html="cleanedQuestionContent"></div>
      </div>
      <div class="selects_warp">
        <Select ref="SelectRef"
                :answer.sync="question.answer"
                :questionId="question.question_id"
                @setAnwer="setAnwer"
                :type="question.topic + ''" />
      </div>
    </div>
  </div>
</template>

<script>
import Select from '@/views/components/selects/index.vue'
export default {
  data () {
    return {
      paperIndex: 0,
    }
  },
  props: {
    question: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    Select
  },
  computed: {
    cleanedQuestionContent () {
      // 使用正则表达式去掉 alt 属性的文字
      let contentWithoutAlt = this.question.question_content.replace(/alt="[^"]*"/g, 'alt=""');
      // 使用正则表达式去掉 title 属性的文字
      return contentWithoutAlt.replace(/title="[^"]*"/g, 'title=""');
    }
  },
  mounted () {
    this.$nextTick(() => {
      let topicContentTd = document.getElementsByTagName('td')
      if (topicContentTd && topicContentTd.length) {
        for (let i = 0; i < topicContentTd.length; i++) {
          if (topicContentTd[i].width) {
            topicContentTd[i].width = topicContentTd[i].width * 18 / 16
          }
        }
      }
    })
  },
  methods: {
    setAnwer (id, answer) {
      this.$emit('setAnwer', id, answer)
    },
  }
}
</script>

<style lang="scss" scoped>
.topic_warp {
  ::v-deep img {
    vertical-align: middle;
  }
  .big {
    width: calc(100% - 120rem);
    display: flex;
    margin-top: 20rem;
    .left {
      flex: 1;
      background: #ffffff;
      box-shadow: inset 0rem -1rem 0rem 0rem rgba(229, 229, 229, 1);
      margin-right: 20rem;
      margin-left: 20rem;
      padding: 20rem;
      overflow: auto;
      .head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10rem;
        color: #666666;
      }
    }
    .right {
      flex: 1;
      .topic {
        padding: 20rem;
        background: #ffffff;
        box-shadow: inset 0rem -1rem 0rem 0rem rgba(229, 229, 229, 1);
      }
    }
  }
  .small {
    width: calc(100% - 200rem);
    background: #ffffff;
    padding: 30rem;
    margin-left: 20rem;
    margin-top: 20rem;
    box-shadow: inset 0rem -1rem 0rem 0rem rgba(229, 229, 229, 1);
  }
}
.title {
  margin-bottom: 20rem;
  font-size: 20rem;
  font-weight: 500;
  color: #666666;
  display: flex;
  justify-content: space-between;
}
.selects_warp {
  margin-top: 50rem;
}
.topic_id {
  font-size: 16rem;
  min-width: 90rem;
}
.content {
  font-size: 20rem;
}
</style>